import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'filterCounter',
  standalone: true,
  pure: false,
})
export class FilterCounterPipe implements PipeTransform {
  transform(form: FormGroup, exclude?: string[]): number {
    const formValue = form.getRawValue();

    Object.keys(formValue).forEach((key: string) => {
      if (exclude?.includes(key)) {
        delete formValue[key];
      }
    });

    return Object.values(formValue)
      .flatMap(item => (item instanceof Object ? Object.values(item).at(0) : item))
      .filter(Boolean).length;
  }
}
