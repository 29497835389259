import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'centsToDollarsPipe',
})
export class CentsToDollarsPipe implements PipeTransform {
  transform(value: number, currencyCode = 'USD'): string {
    const dollars = value / 100;
    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    return new Intl.NumberFormat('en-US', options).format(dollars);
  }
}
