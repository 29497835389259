import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceEmpty',
  standalone: true,
})
export class ReplaceEmptyPipe implements PipeTransform {
  transform<T>(value: T, replaceWith = '-'): T | string {
    if (value === null || value === undefined || value === '') {
      return replaceWith;
    }

    if (typeof value === 'number' && isNaN(value)) {
      return replaceWith;
    }

    // Empty object or empty array
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      return replaceWith;
    }

    return value;
  }
}
