/* eslint-disable @typescript-eslint/no-explicit-any */
export function lineBreakReplacer(value: string): string {
  return value.replace(/(\\r\\n|\\n|\\r)/g, '');
}

export function decodingUnicode(importantInfoValue: string): string {
  return importantInfoValue.replace(/&#(\d+);/g, (m: any, n: any) => String.fromCodePoint(n));
}

export function unescape(unicodeDecodedValue: string): any {
  const escaper: (match: string) => string = (match: string) => {
    return htmlEntitiesMap[match];
  };

  const htmlEntitiesMap: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x60;': '`',
  };

  const source: string = '(?:' + Object.keys(htmlEntitiesMap).join('|') + ')';
  const testRegexp = RegExp(source);
  const replaceRegexp = RegExp(source, 'g');

  if (!unicodeDecodedValue) {
    return;
  }

  return testRegexp.test(unicodeDecodedValue)
    ? unicodeDecodedValue.replace(replaceRegexp, escaper)
    : unicodeDecodedValue;
}

export function replaceHrefWithNative(content: string): string {
  const pattern = new RegExp(`href=["']([^'"]+)["']`, 'gi');

  return content.replace(pattern, (href, group1) => {
    const url = new URL(group1, window.location.origin);

    if (url.origin === window.location.origin) {
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.forEach((value, name) => {
        url.searchParams.append(name, value);
      });

      return `href="${url.toString()}"`;
    }

    return href;
  });
}
