import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'transformStringList',
})
export class TransformStringListPipe implements PipeTransform {
  transform(list: { id: number; name?: string; code?: string }[], divider = '/'): string {
    if (!Array.isArray(list)) {
      return list;
    }

    return list.map(item => item?.name ?? item?.code).join(divider);
  }
}
